import { ApolloProvider } from '@apollo/react-hooks';
import { BindingScope } from '@loopback/context';
import React, { useContext, useEffect, useState } from 'react';
import { StatusBar } from 'react-native';
import 'react-native-gesture-handler';
import { AppNavigator } from './AppNavigator';
import config from './config/default.config.json';
import { ThemeContext, ThemeName, ThemeProvider } from './providers/theme';
import { GraphqlServer } from './servers/Graphqlserver';
import { ViewportProvider } from 'react-viewport-provider';
import { ModalV } from 'react-modal-rs';

import {
  LOGO_STATIC_URI,
  KLEFKI_LOGO_URI,
  HOME_BGI,
  HOME_BGI_BLUE,
  FHP_LOGO,
  HOME_BGI_BLUE_LG,
  PRIMARY_HEADER_BGI,
  WELCOME_INFO_G,
  WELCOME_HEADER_IMG,
  LANDING_BGIMG,
  LANDING_FAMILY_IMG,
  POLICY_TABLE_IMG,
  LANDING_BGIMG_MD,
  LANDING_FAMILY_IMG_MD
} from './services/loadAssets';
import { LoadAssetsV } from 'react-native-load-assets-vfw';
import { VlinderApp } from './services/vlinder-app.service';
import { HttpServer } from './servers/HttpServer';
import { AuthService } from './services/auth-service/auth.service';
import { RestServerService } from './services/rest-server.service';
import { RingLevelService } from './handlers/ring-level-handlers';

export interface PackageInfo {
  name: string;
  version: string;
  description: string;
}

export const pkg: PackageInfo = require('../package.json');
export const EVENT_BUS = 'app.eventbus';
export const CONTEXT_BUFFER = 'app.ctx.buffer';
export const GRAPHQL_CLIENT = 'app.servers.graphql.client';
export const MQTT_CLIENT = 'app.servers.mqtt.client';
export const HTTP_CLIENT = 'app.servers.http.client';
export const AUTH_TOKEN = 'app.servers.http.auth.token';

export const app = VlinderApp.getInstance();
export const ctx = VlinderApp.ctx;
export let authService: any;

//-----------------------------------------------------------------
const fonts = {
  'SFProText-Bold': require('../assets/fonts/SF-Pro-Text-Bold.otf'),
  'SFProText-Semibold': require('../assets/fonts/SF-Pro-Text-Semibold.otf'),
  'SFProText-Regular': require('../assets/fonts/SF-Pro-Text-Regular.otf'),
};
//Load all images here before app launches

const assets: number[] = [
  LOGO_STATIC_URI,
  KLEFKI_LOGO_URI,
  HOME_BGI,
  HOME_BGI_BLUE,
  FHP_LOGO,
  HOME_BGI_BLUE_LG,
  PRIMARY_HEADER_BGI,
  WELCOME_INFO_G,
  WELCOME_HEADER_IMG,
  LANDING_BGIMG,
  LANDING_FAMILY_IMG,
  POLICY_TABLE_IMG,
  LANDING_BGIMG_MD,
  LANDING_FAMILY_IMG_MD
];

//-----------------------------------------------------------------
const initialize = async () => { };

const _setUpBindings = () => {

  authService = AuthService.getInstance();

  RingLevelService.getInstance();
  RestServerService.getInstance({});

  console.log('App started');

  //Set up services / Extension point
};

const _setUpServers = async () => {
  const graphQLServer = new GraphqlServer(config.servers.graphql.uri);
  if (graphQLServer) {
    ctx.bind(GRAPHQL_CLIENT).to(graphQLServer.client);
  }
  // const mqttServer = new MqttServer(
  //   config.servers.mqtt.uri,
  //   config.servers.mqtt.clientId,
  //   config.servers.mqtt.options,
  // );
  // if (mqttServer) {
  //   ctx.bind(MQTT_CLIENT).to(mqttServer);
  // }
  const httpServer = new HttpServer();
  if (httpServer) {
    //Set Http Server Header here
    app.bind(HTTP_CLIENT).to(httpServer);
  }
};

_setUpServers();

//-----------------------------------------------------------------
const AppV = () => {
  const theme = useContext(ThemeContext);
  useEffect(() => {
    const themeProvider = ThemeProvider.getInstance();
    themeProvider.switchTheme(ThemeName.Vlinder);
  }, []);
  return (
    <>
      <StatusBar
        translucent
        backgroundColor="transparent"
        barStyle={theme?.palette.barStyle}
      />
      <AppNavigator />
      <ModalV />
    </>
  );
};

const _ringLevel = async () => {
  console.log('ringLevel Handler =>')
  const ringLevelMessageHandler = RingLevelService.getInstance();
  await ringLevelMessageHandler.handleMessage({});
};

const handleRingLevelUp = async () => {
  await _setUpBindings();
  await _ringLevel();
};

export const App = (): JSX.Element => {
  useEffect(() => {
    initialize();
  }, []);
  return (
    <ThemeProvider>
      <ViewportProvider>
        <ApolloProvider client={ctx.getSync(GRAPHQL_CLIENT)}>
          <LoadAssetsV
            {...{ assets, fonts }}
            performRingLevelUp={handleRingLevelUp}
          >
            <AppV />

          </LoadAssetsV>
        </ApolloProvider>
      </ViewportProvider>
    </ThemeProvider>
  );
};
