export const LOGO_STATIC_URI = require('../../assets/images/vlinder-logo.png');
export const KLEFKI_LOGO_URI = require('../../assets/images/klefki-logo.png');
export const HOME_BGI = require('../../assets/images/home-bgi.png');
export const HOME_BGI_BLUE = require('../../assets/images/home-bgi-blue.png');
export const FHP_LOGO = require('../../assets/images/fhp-logo.png');
export const HOME_BGI_BLUE_LG = require('../../assets/images/home-bgi-blue-lg.png');
export const PRIMARY_HEADER_BGI = require('../../assets/images/primary-header-bgi.png');

export const WELCOME_HEADER_IMG = require('../../assets/images/welcome-header.png')
export const LANDING_BGIMG = require('../../assets/images/landing-bgImg.png');
export const LANDING_FAMILY_IMG = require('../../assets/images/family.png');
export const POLICY_TABLE_IMG = require('../../assets/images/tab.png');
export const LANDING_BGIMG_MD  = require('../../assets/images/landing-bgImg-md.png');
export const LANDING_FAMILY_IMG_MD = require('../../assets/images/family-md.png');
export const WELCOME_INFO_G = require('../../assets/images/welcome-infog.png');
export const POLICY_TABLE_IMG_2 = require('../../assets/images/tab2.png');
export const DESCRIPTION_MAP = require('../../assets/images/map.png');
