//@ts-ignore
import {bind, BindingScope, inject} from '@loopback/core';
import {DataSet} from 'vis-data';
import AsyncStorage from '@react-native-community/async-storage';
import { GraphqlServer } from '../../servers/Graphqlserver';
import { GRAPHQL_CLIENT } from '../../App';
import config from '../../config/default.config.json';
export const AUTH_TOKEN = '@vlinder:auth-token';
export const ACTOR = '@vlinder:actor';
export const USER_PROFILE = '@vlinder:user-profile';
export const GRAHQL_SECONDARY_URL = '@vlinder:graphql-url';
export const FIELD_DATA = '@vlinder:fieldData'
export const COVERAGE_VALUE = '@vlinder:coverageValue';
export const PREMIUM_VALUE = '@vlinder:premiumValue';
export const ACTIVE_CATEGORY = '@vlinder:activeCategory';
export const PREMIUM_PAYOUT = '@vlinder:premiumPayout';
export const COVERAGE_PAYOUT = '@vlinder:coveragePayout';

@bind({scope: BindingScope.SINGLETON})
export class AuthService {
  private static instance: AuthService;
  authTokenDS = new DataSet({
    fieldId: 'id',
  });
  userProfileDS = new DataSet({
    fieldId: 'id',
  });
  actorDS = new DataSet({
    fieldId: 'id',
  });
  graphQLClientDS = new DataSet({
    fieldId: 'id'
  });
  fieldDataDS = new DataSet({
    fieldId: 'id'
  })
  coverageDS = new DataSet({
    fieldId: 'id'
  })
  premiumDS = new DataSet({
    fieldId: 'id'
  });
  activeCategoryDS = new DataSet({
    fieldId: 'id'
  });
  premiumPayoutDS = new DataSet({
    fieldId: 'id'
  })
  coveragePayoutDS = new DataSet({
    fieldId: 'id'
  })
  private constructor() {}
  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }
  reset() {
    this.fieldDataDS.clear();
    this.coverageDS.clear();
    this.premiumDS.clear();
    this.activeCategoryDS.clear();
    this.premiumPayoutDS.clear();
    this.coveragePayoutDS.clear();
} 
  async init() {
    await this.getAuthToken();
    await this.getActor();
    await this.getUserProfile();
    await this.getGraphQLClient();
    await this.getFieldData();
    await this.getCoverageValue();
    await this.getPremiumValue();
    await this.getActiveCategory();
    await this.getPremiumPayout();
    await this.getCoveragePayout();
  }

  async setPremiumPayout(value: any){
    try {
      const _premiumPayout: any = {
        id: PREMIUM_PAYOUT,
        value: value
      }
      this.premiumPayoutDS.update(_premiumPayout);
    }catch(e) {
      //error
    }
  }

  async setCoveragePayout(value: any){
    try {
      const _coveragePayout = {
        id: COVERAGE_PAYOUT,
        value: value
      }
      this.coveragePayoutDS.update(_coveragePayout)
    }catch(e) {
      //error
    }
  }

  async setActiveCategory(value: string){
    try {
      const _activeCat: any ={
        id: ACTIVE_CATEGORY,
        value: value
      }
      this.activeCategoryDS.update(_activeCat);
    } catch(e) {
      //error
    }
  }

  async setCoverageValue(value: string){
    try {
      const _coverageDS: any = {
        id: COVERAGE_VALUE,
        value: value?.toFixed(2)
      }
      this.coverageDS.update(_coverageDS);
    } catch(e){
      //errror
    }
  }

  async setPremiumValue(value: string) {
    try {
      const _premiumDS: any = {
        id: PREMIUM_VALUE,
        value: value?.toFixed(2)
      }
      this.premiumDS.update(_premiumDS);
    } catch(e){
      //errror
    }
  }

  async setFieldData(data: string){
    try {
      const _fieldDataDS: any = {
        id: FIELD_DATA,
        value: data
      }
      this.fieldDataDS.update(_fieldDataDS);
    } catch(e){
      //errror
    }
  }
  async setGraphQLClient(url: string) {
    try {
      await AsyncStorage.setItem(GRAHQL_SECONDARY_URL, url);
      const _graphQLServer = new GraphqlServer(url);
      const _graphQLClientDS: any = {
        id: GRAHQL_SECONDARY_URL,
        url: url,
        client: _graphQLServer?.client,
      }
      this.graphQLClientDS.update(_graphQLClientDS);
    }
    catch (e) {
    }
  }
  async getGraphQLClient() {
    return this.graphQLClientDS.get(GRAHQL_SECONDARY_URL);
    try {
      const value = await AsyncStorage.getItem(GRAHQL_SECONDARY_URL);
      if (value !== null) {
        // value previously stored
        const _graphQLServer = new GraphqlServer(value);
        const _graphQLClientDS = {
          id: GRAHQL_SECONDARY_URL,
          url: value,
          client: _graphQLServer?.client,
        };
        this.graphQLClientDS.update(_graphQLClientDS);
        return _graphQLServer?.client;
      }
      else {
         const _graphQLClientDS = {
          id: GRAHQL_SECONDARY_URL,
          url: config.servers.graphql.uri,
          client: GRAPHQL_CLIENT,
        };
        this.graphQLClientDS.update(_graphQLClientDS);
        return GRAPHQL_CLIENT;
      }
    }
    catch (e) {
      const _graphQLClientDS = {
          id: GRAHQL_SECONDARY_URL,
          url: config.servers.graphql.uri,
          client: GRAPHQL_CLIENT,
        };
        this.graphQLClientDS.update(_graphQLClientDS);
        return GRAPHQL_CLIENT;
    }
  }
  async removeGraphQLClient() {
    try {
      this.graphQLClientDS.clear();
      await AsyncStorage.removeItem(GRAHQL_SECONDARY_URL);
    } catch (e) {
      // remove error
    }
  }
  async setAuthToken(token: string) {
    try {
      await AsyncStorage.setItem(AUTH_TOKEN, token);
      const _authTokenDS = {
        id: AUTH_TOKEN,
        value: token,
      };
      this.authTokenDS.update(_authTokenDS);
    } catch (e) {
      // saving error
    }
  }
  async setActor(actor: string) {
    try {
      await AsyncStorage.setItem(ACTOR, actor);
      const _actorDS = {
        id: ACTOR,
        value: actor,
      };
      this.actorDS.update(_actorDS);
    } catch (e) {
      // saving error
    }
  }
  async setUserProfile(userProfile: any) {
    console.log('set user profile services', userProfile)
    try {
      let _userProfile = userProfile;
      if (userProfile && typeof userProfile !== 'string') {
        _userProfile = JSON.stringify(userProfile);
      }
      await AsyncStorage.setItem(USER_PROFILE, _userProfile);
      const _userProfileDS = {
        id: USER_PROFILE,
        value: userProfile,
      };
      this.userProfileDS.update(_userProfileDS);
    } catch (e) {
      // saving error
    }
  }

  async getPremiumPayout() {
    try {
      const _value = this.premiumPayoutDS.get(PREMIUM_PAYOUT);
      if(!!_value){
        return _value?.value
      }
    } catch(e) {
      //error
    }
  }

  async getCoveragePayout() {
    try {
      const _value = this.coveragePayoutDS.get(COVERAGE_PAYOUT);
      if(!!_value){
        return _value?.value
      }
    } catch(e) {
      //error
    }
  }

  async getActiveCategory() {
    try{
      const _value = this.activeCategoryDS.get(ACTIVE_CATEGORY);
      console.log('active category',_value)
      if(!!_value){
        const _activeCat: any ={
          id: ACTIVE_CATEGORY,
          value: _value
        }
        return _value?.value;
      }
    } catch(e) {}
  }

  async getCoverageValue() {
    try {
      const _value = this.coverageDS.get(COVERAGE_VALUE);
      if(!!_value){
        const _coverageDS = {
          id: COVERAGE_VALUE,
          value: _value?.value
        }
      return _value?.value
      }
    } catch(e){
      //error
    }
  }

  async getPremiumValue() {
    try {
      const _value = this.premiumDS.get(PREMIUM_VALUE);
      if(!!_value){
        const _premiumDS = {
          id: PREMIUM_VALUE,
          value: _value
        }
      return _value?.value
      }
    } catch(e){
      //error
    }
  }

  async getFieldData() {
    try {
      const _value = this.fieldDataDS.get(FIELD_DATA);
      if(!!_value){
        const _fieldDataDS = {
          id: FIELD_DATA,
          value: _value
        }
      return _value?.value
      }
    } catch(e){
      //error
    }
  }
  async getActor() {
    try {
      const value = await AsyncStorage.getItem(ACTOR);
      console.log('inside get actorservice', value);
      if (value !== null) {
        // value previously stored
        const _actorDS = {
          id: ACTOR,
          value: value,
        };
        this.actorDS.update(_actorDS);
        return value;
      }
      else{
        const _value = this.actorDS.get();
        console.log('actor inside else', _value);
        let temp = _value.find(item => {
          //@ts-ignore
          return item?.value;
        });
        //@ts-ignore
        return temp?.value;
      }
    } catch (e) {
      // error reading value
    }
  }
  async getUserProfile() {
    try {
      const value = await AsyncStorage.getItem(USER_PROFILE);
     // console.log('get user profile', value);
      if (value !== null) {
        // value previously stored
        const _userProfileDS = {
          id: USER_PROFILE,
          value: JSON.parse(value),
        };
        this.userProfileDS.update(_userProfileDS);
        return JSON.parse(value);
      }
    } catch (e) {
      // error reading value
    }
  }
  async getAuthToken() {
    try {
      const value = await AsyncStorage.getItem(AUTH_TOKEN);
      if (value !== null) {
        // value previously stored
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: value,
        };
        this.authTokenDS.update(_authTokenDS);
        return value;
      }
    } catch (e) {
      // error reading value
    }
  }

  async removePremiumPayout() {
    try {
      this.premiumPayoutDS.clear()
    } catch(e){
      //error
    }
  }

  async removeCoveragePayout() {
    try {
      this.coveragePayoutDS.clear()
    } catch(e){
      //error
    }
  }

  async removeActiveCategory() {
    try {
      this.activeCategoryDS.clear();
    } catch(e) {}
  }

  async removeCoverageValue() {
    try {
      this.coverageDS.clear();
    } catch(e){
      //error
    }
  }
  
  async removePremiumValue() {
    try {
      this.premiumDS.clear();
    } catch(e){
      //error
    }
  }

  async removeFieldData() {
    try {
      this.fieldDataDS.clear();
    } catch(e){
      //error
    }
  }
  async removeAuthToken() {
    try {
      this.authTokenDS.clear();
      await AsyncStorage.removeItem(AUTH_TOKEN);
    } catch (e) {
      // remove error
    }
  }
  async removeActor() {
    try {
      this.actorDS.clear();
      await AsyncStorage.removeItem(ACTOR);
    } catch (e) {
      // remove error
    }
  }
  async removeUserProfile() {
    try {
      this.userProfileDS.clear();
      await AsyncStorage.removeItem(USER_PROFILE);
    } catch (e) {
      // remove error
    }
  }
  async signIn({
    newToken,
    rememberMe = false,
    actorName,
    userProfile,
  }: {
    newToken: string;
    rememberMe?: boolean;
    actorName?: string;
    userProfile?: any;
  }) {
    // console.log('inside user sign in', userProfile);
    if (rememberMe) {
      if (newToken) {
        await this.setAuthToken(newToken);
      }
      if (actorName) {
        await this.setActor(actorName);
      }
      if (userProfile) {
        console.log('inside user sign in set profile 1', userProfile);
        await this.setUserProfile(userProfile);
      }
    } else {
      if (newToken) {
        const _authTokenDS = {
          id: AUTH_TOKEN,
          value: newToken,
        };
        this.authTokenDS.update(_authTokenDS);
      }
      if (actorName) {
        const _actorDS = {
          id: ACTOR,
          value: actorName,
        };
        this.actorDS.update(_actorDS);
      }
      if (userProfile) {
        console.log('inside user sign in set profile', userProfile);
        // const _userProfileDS = {
        //   id: USER_PROFILE,
        //   value: userProfile,
        // };
        // this.userProfileDS.update(_userProfileDS);
        await this.setUserProfile(userProfile);
      }
    }
  }
  async signOut() {
    await this.removeAuthToken();
    await this.removeActor();
    await this.removeUserProfile();
    await this.removeGraphQLClient();
    await this.removeFieldData();
    await this.removeCoverageValue();
    await this.removePremiumValue();
    await this.removeActiveCategory();
  }
  isAuthenticated() {
    const token = this.authTokenDS.get(AUTH_TOKEN);
    // console.log('auth token', token);
    // return true;
    if (token) {
      return true;
    }
    return false;
  }
}