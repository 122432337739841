// @ts-nocheck
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';

import { LoaderV } from '../widgets/LoaderV';

import { useAuth } from '../services/auth-service/auth.provider';

import config from '../config/default.config.json';

const homeURL = config?.homepageURL;

const { isAuthenticated } = useAuth();

const WelcomeScreen = lazy(() => import('../screens/Welcome'));
const HomeScreen = lazy(() => import('../screens/Home'));
const PolicyProcessScreen = lazy(() => import('../screens/PolicyProcess'));
const PolicyDetailsScreen = lazy(() => import('../screens/PolicyDetails'));
const ViewPolicyScreen = lazy(() => import('../screens/ViewPolicy'));
const ViewPayoutScreen = lazy(() => import('../screens/ViewPayouts'));
const PayMyPremiumScreen = lazy(() => import('../screens/ViewPremium'));
const LandingScreen = lazy(() => import('../screens/LandingPage'));
const VerifierScreen = lazy(() => import('../screens/VerifierView'));
const NotFoundScreen = lazy(() => import('../screens/PageNotFound'));
const TermsandConditionScreen = lazy(() => import('../screens/TermsAndCondition'));
const PrivacyScreen = lazy(() => import('../screens/PrivacyPolicy'));


const RouterConfig = props => (
  <Switch>
    <PrivateRoute path={`${homeURL}/view-premium`} component={PayMyPremiumScreen} />
    <PrivateRoute path={`${homeURL}/view-payout`} component={ViewPayoutScreen} />
    <PrivateRoute path={`${homeURL}/view-policy`} component={ViewPolicyScreen} />
    <PrivateRoute path={`${homeURL}/policy-details`} component={PolicyDetailsScreen} />
    <PrivateRoute path={`${homeURL}/welcome`} component={WelcomeScreen} />
    <PrivateRoute path={`${homeURL}/policy-process`} component={PolicyProcessScreen} />
     
    <Route path={`${homeURL}`} component={HomeScreen} />
    <Route path="/termsandcondition" component={TermsandConditionScreen} />
    <Route path="/privacy" component={PrivacyScreen} />
    <Route path="/hello" component={NotFoundScreen} />
    <Route path="/credential" component={VerifierScreen} />
    <Route path="/" component={LandingScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: `${homeURL}`,
          }}
        />
      )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        {/* <Stack.Screen name="VerifierScreen" component={VerifierScreen} /> */}
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <Suspense fallback={<LoaderV loadingText={'loading'} />}>
        <RouterConfig />
      </Suspense>
    </Router>
  );
};
