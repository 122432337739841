import { bind, BindingScope, config, inject } from '@loopback/core';

export interface IRestServerOptions {

}

@bind({ scope: BindingScope.SINGLETON })
export class RestServerService {
    private static instance: RestServerService;
    
    private constructor(
    ) {
    }

    static getInstance(options?: IRestServerOptions): RestServerService {
    if (!RestServerService.instance) {
      RestServerService.instance = new RestServerService();
    }

    return RestServerService.instance;
  }

    async init() {
        
    }
    
}